export class UsuarioEntity {
    constructor() {
    }

    public usuario_id: string = null;
    public usuario_usuario: string = null;
    public usuario_clave: string = null;
    public usuario_nombres: string = null;
    public usuario_apellidos: string = null;
    public usuario_cargo: string = null;
    public usuario_cargotext: string = null;
    public usuario_estado: string = null;
    public usuario_correo: string = null;
    public sede_id: string = null;
    public puesto_id: string = null;
    public sedesList: string[] = [];
    public puestosList: string[] = [];

    public getUsuario_id(): string {
        return this.usuario_id;
    }

    public setUsuario_id(value: string) {
        this.usuario_id = value;
    }

    public getUsuario_usuario(): string {
        return this.usuario_usuario;
    }

    public setUsuario_usuario(value: string) {
        this.usuario_usuario = value;
    }

    public getUsuario_clave(): string {
        return this.usuario_clave;
    }

    public setUsuario_clave(value: string) {
        this.usuario_clave = value;
    }

    public getUsuario_nombres(): string {
        return this.usuario_nombres;
    }

    public setUsuario_nombres(value: string) {
        this.usuario_nombres = value;
    }

    public getUsuario_apellidos(): string {
        return this.usuario_apellidos;
    }

    public setUsuario_apellidos(value: string) {
        this.usuario_apellidos = value;
    }

    public getUsuario_cargo(): string {
        return this.usuario_cargo;
    }

    public setUsuario_cargo(value: string) {
        this.usuario_cargo = value;
    }

    public getUsuario_cargotext(): string {
        return this.usuario_cargotext;
    }

    public setUsuario_cargotext(value: string) {
        this.usuario_cargotext = value;
    }

    public getUsuario_estado(): string {
        return this.usuario_estado;
    }

    public setUsuario_estado(value: string) {
        this.usuario_estado = value;
    }

    public getSede_id(): string {
        return this.sede_id;
    }

    public setSede_id(value: string) {
        this.sede_id = value;
    }

    public getPuesto_id(): string {
        return this.puesto_id;
    }

    public setPuesto_id(value: string) {
        this.puesto_id = value;
    }

    public toString(): string {
        return this.getUsuario_id();
    }
}
