import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { Page404Component } from "./shared/components/ui/authentication/page404/page404.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { Role } from "./shared/models/role";
import { AuthLayoutComponent } from "./shared/components/main-view/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./shared/components/main-view/app-layout/main-layout/main-layout.component";
const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/authentication/signin", pathMatch: "full" },
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'trabajador',
        loadChildren: () => import('./modules/trabajador/trabajador-lista.module').then(m => m.TrabajadorListaModule),
      },
      {
        path: 'sistema',
        loadChildren: () => import('./modules/datasistema/datasistema.module').then(m => m.DatasistemaModule),
      },
      {
        path: 'usuario',
        loadChildren: () => import('./modules/usuario/usuario-lista.module').then(m => m.UsuarioListaModule),
      },
      {
        path: "registro",
        loadChildren: () =>
          import("./modules/registro/registro.module").then((m) => m.RegistroModule),
      },
      {
        path: "asistencia",
        loadChildren: () =>
          import("./modules/asistencia/asistencia.module").then((m) => m.AsistenciaModule),
      },
      {
        path: "seguimiento",
        loadChildren: () =>
          import("./modules/seguimiento/seguimiento-lista.module").then((m) => m.SeguimientoListaModule),
      },
      {
        path: "reportes",
        loadChildren: () =>
          import("./modules/reportes/reportes-lista.module").then((m) => m.ReportesListaModule),
      },
      {
        path: "admin",
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import("./shared/components/ui/admin/admin.module").then((m) => m.AdminModule),
      },
      {
        path: "doctor",
        canActivate: [AuthGuard],
        data: {
          role: Role.Doctor,
        },
        loadChildren: () =>
          import("./shared/components/ui/doctor/doctor.module").then((m) => m.DoctorModule),
      },
      {
        path: "patient",
        canActivate: [AuthGuard],
        data: {
          role: Role.Patient,
        },
        loadChildren: () =>
          import("./shared/components/ui/patient/patient.module").then((m) => m.PatientModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./shared/components/ui/calendar/calendar.module").then((m) => m.CalendarsModule),
      },
      {
        path: "task",
        loadChildren: () =>
          import("./shared/components/ui/task/task.module").then((m) => m.TaskModule),
      },
      {
        path: "contacts",
        loadChildren: () =>
          import("./shared/components/ui/contacts/contacts.module").then((m) => m.ContactsModule),
      },
      {
        path: "email",
        loadChildren: () =>
          import("./shared/components/ui/email/email.module").then((m) => m.EmailModule),
      },
      {
        path: "calendar",
        loadChildren: () =>
          import("./shared/components/ui/apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "apps",
        loadChildren: () =>
          import("./shared/components/ui/apps/apps.module").then((m) => m.AppsModule),
      },
      {
        path: "widget",
        loadChildren: () =>
          import("./shared/components/ui/widget/widget.module").then((m) => m.WidgetModule),
      },
      {
        path: "ui",
        loadChildren: () => import("./shared/components/ui/ui/ui.module").then((m) => m.UiModule),
      },
      {
        path: "forms",
        loadChildren: () =>
          import("./shared/components/ui/forms/forms.module").then((m) => m.FormModule),
      },
      {
        path: "tables",
        loadChildren: () =>
          import("./shared/components/ui/tables/tables.module").then((m) => m.TablesModule),
      },
      {
        path: "media",
        loadChildren: () =>
          import("./shared/components/ui/media/media.module").then((m) => m.MediaModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./shared/components/ui/charts/charts.module").then((m) => m.ChartsModule),
      },
      {
        path: "timeline",
        loadChildren: () =>
          import("./shared/components/ui/timeline/timeline.module").then((m) => m.TimelineModule),
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./shared/components/ui/icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "extra-pages",
        loadChildren: () =>
          import("./shared/components/ui/extra-pages/extra-pages.module").then(
            (m) => m.ExtraPagesModule
          ),
      },
      {
        path: "maps",
        loadChildren: () =>
          import("./shared/components/ui/maps/maps.module").then((m) => m.MapsModule),
      },
      {
        path: "multilevel",
        loadChildren: () =>
          import("./shared/components/ui/multilevel/multilevel.module").then(
            (m) => m.MultilevelModule
          ),
      },
    ],
  },
  {
    path: "authentication",
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./shared/components/ui/authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "external",
    loadChildren: () => import("./modules/external/external.module").then(
      (m) => m.ExternalModule)
  },
  { path: "**", component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
