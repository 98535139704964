export enum Role {
  All = "All",
  Admin = "Admin",
  Doctor = "Doctor",
  Patient = "Patient",


  CARGO_ADMIN = "1",
  CARGO_DOCTOR = "2",
  CARGO_JEFE = "3",
  CARGO_SISTEMAS = "4",

}
