import {Sede} from "../models/sede.model";

export class TrabajadorEntity {
    constructor() {
    }

    public trabajador_id: string = null;
    public puesto_id: string = null;
    public area_id: string = null;
    public sede_id: string = null;
    public trabajador_nombres: string = null;
    public trabajador_apellidos: string = null;
    public trabajador_dni: string = null;
    public trabajador_estado: string = null;
    public trabajador_fechanacimiento: string = null;
    public trabajador_lugar: string = null;
    public trabajador_celular: string = null;
    public trabajador_email: string = null;
    public trabajador_direccion: string = null;
    public trabajador_numeroemergencia: string = null;
    public trabajador_fechaingreso: string = null;
    public trabajador_fechacese: string = null;
    public trabajador_renovacioncontrato: string = null;
    public trabajador_fecharegistro: string = null;
    public trabajador_nombrescompletos: string = null;
    public trabajador_celularcorporativo: string = null;
    public trabajador_motivocese: string = null;
    public trabajador_ficheroemo: string = null;
    public trabajador_ficheroemofecha: string = null;
    public sede: Sede;
    public trabajador_tipopermiso: string = null;
    public trabajador_iniciopermiso: string = null;
    public trabajador_finpermiso: string = null;
    public trabajador_motivopermiso: string = null;
    public trabajador_motivoalta: string = null;
    public trabajador_esplanilla: string = null;
    public trabajador_foto: string = null;

    public getTrabajador_id(): string {
        return this.trabajador_id;
    }

    public setTrabajador_id(value: string) {
        this.trabajador_id = value;
    }

    public getPuesto_id(): string {
        return this.puesto_id;
    }

    public setPuesto_id(value: string) {
        this.puesto_id = value;
    }

    public getArea_id(): string {
        return this.area_id;
    }

    public setArea_id(value: string) {
        this.area_id = value;
    }

    public getSede_id(): string {
        return this.sede_id;
    }

    public setSede_id(value: string) {
        this.sede_id = value;
    }

    public getTrabajador_nombres(): string {
        return this.trabajador_nombres;
    }

    public setTrabajador_nombres(value: string) {
        this.trabajador_nombres = value;
    }

    public getTrabajador_apellidos(): string {
        return this.trabajador_apellidos;
    }

    public setTrabajador_apellidos(value: string) {
        this.trabajador_apellidos = value;
    }

    public getTrabajador_dni(): string {
        return this.trabajador_dni;
    }

    public setTrabajador_dni(value: string) {
        this.trabajador_dni = value;
    }

    public getTrabajador_estado(): string {
        return this.trabajador_estado;
    }

    public setTrabajador_estado(value: string) {
        this.trabajador_estado = value;
    }

    public getTrabajador_fechanacimiento(): string {
        return this.trabajador_fechanacimiento;
    }

    public setTrabajador_fechanacimiento(value: string) {
        this.trabajador_fechanacimiento = value;
    }

    public getTrabajador_lugar(): string {
        return this.trabajador_lugar;
    }

    public setTrabajador_lugar(value: string) {
        this.trabajador_lugar = value;
    }

    public getTrabajador_celular(): string {
        return this.trabajador_celular;
    }

    public setTrabajador_celular(value: string) {
        this.trabajador_celular = value;
    }

    public getTrabajador_email(): string {
        return this.trabajador_email;
    }

    public setTrabajador_email(value: string) {
        this.trabajador_email = value;
    }

    public getTrabajador_direccion(): string {
        return this.trabajador_direccion;
    }

    public setTrabajador_direccion(value: string) {
        this.trabajador_direccion = value;
    }

    public getTrabajador_numeroemergencia(): string {
        return this.trabajador_numeroemergencia;
    }

    public setTrabajador_numeroemergencia(value: string) {
        this.trabajador_numeroemergencia = value;
    }

    public getTrabajador_fechaingreso(): string {
        return this.trabajador_fechaingreso;
    }

    public setTrabajador_fechaingreso(value: string) {
        this.trabajador_fechaingreso = value;
    }

    public getTrabajador_fechacese(): string {
        return this.trabajador_fechacese;
    }

    public setTrabajador_fechacese(value: string) {
        this.trabajador_fechacese = value;
    }

    public getTrabajador_renovacioncontrato(): string {
        return this.trabajador_renovacioncontrato;
    }

    public setTrabajador_renovacioncontrato(value: string) {
        this.trabajador_renovacioncontrato = value;
    }

    public getTrabajador_fecharegistro(): string {
        return this.trabajador_fecharegistro;
    }

    public setTrabajador_fecharegistro(value: string) {
        this.trabajador_fecharegistro = value;
    }
    public getTrabajador_nombrescompletos(): string {
        return this.trabajador_nombrescompletos;
    }

    public setTrabajador_nombrescompletos(value: string) {
        this.trabajador_nombrescompletos = value;
    }
    public getTrabajador_celularcorporativo(): string {
        return this.trabajador_celularcorporativo;
    }

    public setTrabajador_celularcorporativo(value: string) {
        this.trabajador_celularcorporativo = value;
    }
    public getTrabajador_motivocese(): string {
        return this.trabajador_motivocese;
    }

    public setTrabajador_motivocese(value: string) {
        this.trabajador_motivocese = value;
    }
    public getTrabajador_ficheroemo(): string {
        return this.trabajador_ficheroemo;
    }

    public setTrabajador_ficheroemo(value: string) {
        this.trabajador_ficheroemo = value;
    }
    public getTrabajador_ficheroemofecha(): string {
        return this.trabajador_ficheroemofecha;
    }

    public setTrabajador_ficheroemofecha(value: string) {
        this.trabajador_ficheroemofecha = value;
    }

    public getTrabajador_trabajador_foto(): string {
        return this.trabajador_foto;
    }

    public setTrabajador_trabajador_foto(value: string) {
        this.trabajador_foto = value;
    }


    public toString(): string {
        return this.getTrabajador_id();
    }
}
