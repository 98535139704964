import { NgModule } from '@angular/core';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {SelectCheckAllComponent} from "./select-check-all/select-check-all.component";

@NgModule({
    imports: [
        MatSelectModule, MatCheckboxModule
    ],
    exports: [
        MatSelectModule, MatCheckboxModule, SelectCheckAllComponent
    ],
    declarations: [SelectCheckAllComponent]
})
export class CustomModule {
}
