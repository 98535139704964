import { TrabajadorEntity } from '../entity/trabajador.entity';
import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { CommonResponse } from "../response/common.response";
import { ApiTrabajadorRepository } from "../../data/datasource/api/trabajador/api.trabajador.repository";
import { UtilityService } from "../services/utility.service";
import { LocalUsuarioRepository } from "../../data/datasource/local/usuario/local.usuario.repository";

@Injectable({
    providedIn: 'root'
})
export class Trabajador extends TrabajadorEntity {
    PARAM = environment.PARAM;

    constructor(
        public apiTrabajadorRepository?: ApiTrabajadorRepository,
        public localTrabajadorRepository?: LocalUsuarioRepository
    ) {
        super();
    }

    /**
     * Armar el objeto en base a sus atributos: valor
     * Valido si el service 'TrabajadorService' esta inyectado y es un objeto,
     * para pasarlo como parametro en el constructor
     */
    public static build(data: {}, apiTrabajadorRepository?: ApiTrabajadorRepository) {
        const trabajador = new Trabajador();
        trabajador.apiTrabajadorRepository = apiTrabajadorRepository;
        // tslint:disable-next-line:forin
        for (const propiedad in data) {
            trabajador[propiedad] = data[propiedad];
        }
        return trabajador;
    }

    public async login(credenciales: any): Promise<CommonResponse> {
        const response = await this.apiTrabajadorRepository.login(credenciales);
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                    // @ts-ignore
                    .map(Trabajador.build)
                    .filter(Boolean);
            } else {
                response.data = Trabajador.build(response.data);
            }
        }
        return response;
    }

    public guardarSesion(sesionData) {
        if (sesionData.token) {
            this.localTrabajadorRepository.guardarUsuarioSesion(sesionData);
            this.localTrabajadorRepository.guardarToken(sesionData.token);
        }
    }

    public async deleteTokenByToken(): Promise<CommonResponse> {
        const response = await this.apiTrabajadorRepository.deleteTokenByToken(this.localTrabajadorRepository.obtenerToken());
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                    // @ts-ignore
                    .map(Trabajador.build)
                    .filter(Boolean);
            } else {
                response.data = Trabajador.build(response.data);
            }
        }
        return response;
    }

    public async obtenerListaPorPaginacion(obj: any): Promise<CommonResponse> {
        const response = await this.apiTrabajadorRepository.obtenerListaPorPaginacion(obj);
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                    // @ts-ignore
                    .map(Trabajador.build)
                    .filter(Boolean);
            } else {
                response.data = Trabajador.build(response.data);
            }
        }
        return response;
    }

    public async registrar(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.registrar(objeto);
    }

    public async editar(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.editar(objeto);
    }

    public async inactivar(id: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.inactivar(id);
    }

    public async activar(id: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.activar(id);
    }

    public async habilitar(id: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.habilitar(id);
    }

    public async getPuestos(): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getPuestos();
    }

    public async getSedes(): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getSedes();
    }

    public async getAreas(): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getAreas();
    }

    public async getDataDashboard(): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getDataDashboard();
    }
    public async getSintomasControlDiario(trabajadorid): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getSintomasControlDiario(trabajadorid);
    }
    public async registrarSeguimiento(trabajadorid, objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.registrarSeguimiento(trabajadorid, objeto);
    }
    public async editarSeguimiento(seguimientoid, objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.editarSeguimiento(seguimientoid, objeto);
    }
    public async obtenerListaPorPaginacionSeguimiento(busqueda: any, pagina: any, registros: any, objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.obtenerListaPorPaginacionSeguimiento(busqueda, pagina, registros, objeto);
    }
    public async getbyID(trabajadorid): Promise<CommonResponse> {
        const response = await this.apiTrabajadorRepository.getbyID(trabajadorid);
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                    // @ts-ignore
                    .map(Trabajador.build)
                    .filter(Boolean);
            } else {
                response.data = Trabajador.build(response.data);
            }
        }
        return response;
    }
    public async getHistorialSeguimiento(trabajadorid: any, pagina: any, registros: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getHistorialSeguimiento(trabajadorid, pagina, registros);
    }
    public async getSeguimiento(seguimientoid: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getSeguimiento(seguimientoid);
    }

    public async darAlta(seguimientoid: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.darAlta(seguimientoid);
    }

    public async adjuntarArchivo(file: FormData): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.adjuntarArchivo(file);
    }

    public async reporteCesePaginado(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.reporteCesePaginado(objeto);
    }

    public async procesarInformacionExcel(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.procesarInformacionExcel(objeto);
    }

    public async procesarDataTrabajadorExcel(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.procesarDataTrabajadorExcel(objeto);
    }

    public async comorbilidadList(trabajadorid): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.comorbilidadList(trabajadorid);
    }

    public async addComorbilidad(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.addComorbilidad(objeto);
    }

    public async deleteComorbilidad(id: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.deleteComorbilidad(id);
    }

    public async agregarDias(trajadorid, objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.agregarDias(trajadorid, objeto);
    }

    public async getDias(trajadorid): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getDias(trajadorid);
    }

    public async getTiposPermisoTrabajador(): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getTiposPermisoTrabajador();
    }

    public async actualizarFechaMarcacion(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.actualizarFechaMarcacion(objeto);
    }

    public async enviarCorreoAltaPersonal(): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.enviarCorreoAltaPersonal();
    }

    public async enviarCorreoCesePersonal(trabajadorid: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.enviarCorreoCesePersonal(trabajadorid);
    }

    public async generarFotocheck(trabajadorid: any): Promise<Blob> {
        return await this.apiTrabajadorRepository.generarFotocheck(trabajadorid);
    }

    public async validarFotocheck(dni: string): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.validarFotocheck(dni);
    }

    public async reenviarCorreoCesePersonal(trabajadorid: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.reenviarCorreoCesePersonal(trabajadorid);
    }

    public async getDocumentosByTrabajador(trabajadorid: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.getDocumentosByTrabajador(trabajadorid);
    }

    public async agregarDocumento(objeto: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.agregarDocumento(objeto);
    }

    public async eliminarDocumento(documentoid: any): Promise<CommonResponse> {
        return await this.apiTrabajadorRepository.eliminarDocumento(documentoid);
    }


    public cerrarSesion() {
        this.localTrabajadorRepository.eliminarUsuarioSesion();
        this.localTrabajadorRepository.eliminarToken();
    }

    /**
     * Valida si el producto tiene un id valido
     */
    public tieneIdValido() {
        return UtilityService.esUnIDValido(this.trabajador_id);
    }

    /**
     * Valida si el objeto esta activo
     */
    public estaActivo() {
        // tslint:disable-next-line:triple-equals
        return this.trabajador_estado == this.PARAM.ACTIVO;
    }

    /**
     * Valida si el objeto esta activo
     */
    public estadoDescripcion() {
        // tslint:disable-next-line:triple-equals
        return this.estaActivo() ? 'Activo' : 'Inactivo';
    }

    /**
     * Resetar el objeto con valores por defecto
     */
    public reset() {
        this.setTrabajador_estado(this.PARAM.ACTIVO);
    }

}
