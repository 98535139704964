import {Injectable} from '@angular/core';
import {LocalUsuarioRepository} from "../../data/datasource/local/usuario/local.usuario.repository";
import {Usuario} from "../models/usuario.model";

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    constructor(
        private localUsuarioRepository: LocalUsuarioRepository
    ) {
    }

    public existeSesion() {
        const token = this.localUsuarioRepository.obtenerToken();
        return token && token != '';
    }

    public usuarioLogeado(): Usuario {
        return this.localUsuarioRepository.obtenerUsuarioSesion();
    }
    public localIdSesion(): string {
        const usuarioLogeado: any = this.usuarioLogeado();
        if (usuarioLogeado) {
            return usuarioLogeado.local_id;
        }
    }


}

