import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../../../../environments/environment";
import { CommonResponse } from "../../../../shared/response/common.response";
import { SecurityService } from "../../../../shared/services/security.service";
import { UtilityService } from "../../../../shared/services/utility.service";
import { Trabajador } from "../../../../shared/models/trabajador.model";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ApiTrabajadorRepository {
    /**
     * Contiene la ruta de conexión a servicios publicos
     */
    API_URI = environment.API_URI;
    PUBLIC_URI = environment.PUBLIC_URI;

    // tslint:disable-next-line:max-line-length
    constructor(private http: HttpClient, private securityService: SecurityService, private utilityService: UtilityService) {
    }

    public async login(credentials: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.PUBLIC_URI + 'trabajador/login', credentials).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async deleteTokenByToken(token: string): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.PUBLIC_URI + 'usuario/deleteTokenByToken/' + token).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async obtenerListaPorPaginacion(obj: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/listarPorPaginacionPost', obj).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async registrar(objeto: Trabajador): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/trabajador', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async editar(objeto: Trabajador): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.put(this.API_URI + 'trabajador/trabajador', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async inactivar(id): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/inactivar/' + id).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async activar(id): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/activar/' + id).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async habilitar(id): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/habilitar/' + id).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getPuestos(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'puesto/getAllActivos').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getSedes(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'sede/getAllActivos').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getAreas(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'area/getAllActivos').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getDataDashboard(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/getDataDashboard').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getSintomasControlDiario(trabajadorid): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/getSintomasControlDiario/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async registrarSeguimiento(trabajadorid, objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/registrarSeguimiento/' + trabajadorid, objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async editarSeguimiento(seguimientoid, objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.put(this.API_URI + 'trabajador/editarSeguimiento/' + seguimientoid, objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async obtenerListaPorPaginacionSeguimiento(busqueda: any, pagina: any, registros: any, objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/listarPaginacionSeguimiento/' + busqueda + '/' + pagina + '/' + registros, objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }
    public async getbyID(trabajadorid): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/trabajador/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }
    public async getHistorialSeguimiento(trabajadorid: any, pagina: any, registros: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/getHistorialSeguimiento/' + trabajadorid + '/' + pagina + '/' + registros).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getSeguimiento(seguimientoid: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/getSeguimiento/' + seguimientoid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async darAlta(seguimientoid: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/darAlta/' + seguimientoid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async adjuntarArchivo(file: FormData): Promise<CommonResponse> {
        try {
            // tslint:disable-next-line:max-line-length
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/adjuntarArchivo', file).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async reporteCesePaginado(objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/reporteCesePaginado', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async procesarInformacionExcel(objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/procesarInformacionExcel', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async procesarDataTrabajadorExcel(objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/procesarDataTrabajadorExcel', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async comorbilidadList(trabajadorid): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'comorbilidad/listarPorPaginacion/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async addComorbilidad(objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'comorbilidad/comorbilidad', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async deleteComorbilidad(id: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.delete(this.API_URI + 'comorbilidad/comorbilidad/' + id).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async agregarDias(trabajadorid, objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.put(this.API_URI + 'trabajador/agregarDias/' + trabajadorid, objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getDias(trabajadorid): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/getDias/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getTiposPermisoTrabajador(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/getTiposPermisoTrabajador').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async actualizarFechaMarcacion(objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'trabajador/actualizarFechaMarcacion', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async enviarCorreoAltaPersonal(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/enviarCorreoAltaPersonal').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async enviarCorreoCesePersonal(trabajadorid: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/enviarCorreoCesePersonal/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async generarFotocheck(trabajadorid: any): Promise<Blob> {
        try {
            return this.http.post<Blob>(`${this.API_URI}trabajador/${trabajadorid}/fotocheck`, null, {
                responseType: 'blob' as 'json'
            }).toPromise();
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async validarFotocheck(dni: any): Promise<CommonResponse> {
        try {
            const responseSuccess = this.http.get(`${this.API_URI}trabajador/${dni}/fotocheck`).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async reenviarCorreoCesePersonal(trabajadorid: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'trabajador/reenviarCorreoCesePersonal/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getDocumentosByTrabajador(trabajadorid: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'documento/getDocumentosByTrabajador/' + trabajadorid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async agregarDocumento(objeto: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'documento/documento', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async eliminarDocumento(documentoid: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'documento/eliminarDocumento/' + documentoid).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }



}
