import {Injectable} from '@angular/core';
import {Usuario} from "../../../../shared/models/usuario.model";

@Injectable({
    providedIn: 'root'
})

export class LocalUsuarioRepository {

    constructor() {
    }

    /**
     * Datos de sesión
     */


    public obtenerToken() {
        return localStorage.getItem('token');
    }

    public guardarToken(token: string) {
        localStorage.setItem('token', token);
    }

    public eliminarToken() {
        localStorage.removeItem('token');
    }

    public obtenerUsuarioSesion(): Usuario {
        return Usuario.build(JSON.parse(localStorage.getItem('usuarioSesion')));
    }

    public guardarUsuarioSesion(usuarioObj: Usuario) {
        localStorage.setItem('usuarioSesion', JSON.stringify(usuarioObj));
    }

    public eliminarUsuarioSesion() {
        localStorage.removeItem('usuarioSesion');
    }
}
