import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {isNumeric} from 'rxjs/internal-compatibility';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor(

    ) {
    }
    static PARAM = environment.PARAM;
    CDN_BASE = environment.CDN_BASE;
    HTTP_RESPONSE = environment.HTTP_RESPONSE;

    public static formatearFecha(fecha, formato): any {
        let fechaSeteada = "";
        if (fecha && fecha !== '-') {
            fechaSeteada = moment(fecha).format(formato);
        }
        return fechaSeteada;
    }

    public static esUnIDValido(id: string) {
        return isNumeric(id) && parseInt(id) > 0;
    }

    public static listaEstadoFiltros(): any[] {
        return [
            {id: "-1", value: "Todos..."},
            {id: "1", value: "Activos"},
            {id: "0", value: "Inactivos"}
        ];
    }

    public static obtenerListaDiasDeLaSemana() {
        return [
            {value: '1', label: 'Lunes'},
            {value: '2', label: 'Martes'},
            {value: '3', label: 'Miercoles'},
            {value: '4', label: 'Jueves'},
            {value: '5', label: 'Viernes'},
            {value: '6', label: 'Sábado'},
            {value: '7', label: 'Domingo'}
        ];
    }

    public obtenerArregloCategoriasInforme() {

        const CategoriasList =
            [
                {
                    categoria_id: 1,
                    nombre: 'Informes para BASC',
                    descripcion: 'Diversos informes que brindan información relacionada con el trabajador.'
                }
            ];
        return CategoriasList;
    }

    public obtenerArregloInformesGenerales() {
        const reporteList =
            [
                {
                    informe_id: 1,
                    descripcion: 'Usuarios y Accesos Asignados',
                    url: '',
                    page: 'trabajador_reporteprivilegios',
                    categoria_id: 1,
                    type: 'reports'
                },
                {
                    informe_id: 2,
                    descripcion: 'Plan de Mantenimiento',
                    url: '',
                    page: 'trabajador_reportemantenimiento',
                    categoria_id: 1,
                    type: 'reports'
                },
                {
                    informe_id: 3,
                    descripcion: 'Cambio de Claves',
                    url: '',
                    page: 'trabajador_reporteclaves',
                    categoria_id: 1,
                    type: 'reports'
                },
                {
                    informe_id: 4,
                    descripcion: 'Inventario de Equipos',
                    url: '',
                    page: 'trabajador_reporteinventarioequipo',
                    categoria_id: 1,
                    type: 'reports'
                },
                {
                    informe_id: 5,
                    descripcion: 'Inspección de Equipos',
                    url: '',
                    page: 'trabajador_reporteinspeccion',
                    categoria_id: 1,
                    type: 'reports'
                }
            ];

        return reporteList;
    }

    /**
     * Metodo que valida si una imagen es de cloudinary para asignarle el path del CDN o no
     * @param urlImagen
     */

    public static obtenerCDNBaseImagen(urlImagen, dimensiones?) {
        if (urlImagen.startsWith('galery/')) {
            if (dimensiones) {
                return environment.CDN_CLOUDINARY + dimensiones + urlImagen;
            }
            return environment.CDN_CLOUDINARY + urlImagen;
        }
        return urlImagen;
    }

    public static jsonTranslateEspanol() {
        return {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
            monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
            today: 'Hoy',
            clear: 'Borrar'
        };
    }

    public static round(value, exp) {
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math.round(value);
        }
        value = this.round2(value, exp + 2);
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        value = value.toString().split('e');
        value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
    }

    public static round2(value, exp) {
        if (typeof exp === 'undefined' || +exp === 0) {
            return Math.round(value);
        }
        value = +value;
        exp = +exp;
        if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
        }
        value = value.toString().split('e');
        value = Math.round(+(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp)));
        value = value.toString().split('e');
        return +(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp));
    }

    public static obtenerListaComprobantes(conTodos?) {
        const listaComprobantes = [];
        if (conTodos == this.PARAM.SI) {
            listaComprobantes.push({label: 'Todos', value: this.PARAM.TODOS, icon: 'fa fa-fw fa-book'});
        }
        listaComprobantes.push({label: 'Factura', value: 'Factura', icon: 'fa fa-fw fa-book'});
        listaComprobantes.push({label: 'Boleta', value: 'Boleta', icon: 'fa fa-fw fa-book'});
        return listaComprobantes;
    }

    public static obtenerRangofechasFormateada(listaRangoFechas) {
        const rangoFechas: any = {};
        rangoFechas.fechaInicial = this.PARAM.TODOS;
        rangoFechas.fechaFinal = this.PARAM.TODOS;
        if (Array.isArray(listaRangoFechas) && listaRangoFechas.length > 1) {
            rangoFechas.fechaInicial = UtilityService.formatearFecha(listaRangoFechas[0], 'yyyy-MM-DD 00:00:00');
            rangoFechas.fechaFinal = UtilityService.formatearFecha(listaRangoFechas[1], 'yyyy-MM-DD 23:59:59');
        }
        return rangoFechas;
    }

    public static obtenerInicioDeMesFormatoDate() {
        const fechaActual = new Date();
        return new Date(moment(new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1)).format('L') + ' 00:00:00');
    }

    public static obtenerListaIsocode(conTodos?): any[] {
        const listaIsocode = [];
        if (conTodos == this.PARAM.SI) {
            listaIsocode.push({label: 'Todos', value: this.PARAM.TODOS});
        }
        listaIsocode.push({label: 'PEN', value: 'PEN'});
        listaIsocode.push({label: 'USD', value: 'USD'});
        listaIsocode.push({label: 'EUR', value: 'EUR'});
        return listaIsocode;
    }

    public static obtenerListaEstadosOrdecompra(conTodos?) {
        const listaEstadoOrdencompra = [];
        if (conTodos == this.PARAM.SI) {
            listaEstadoOrdencompra.push({label: 'Todos', value: this.PARAM.TODOS});
        }
        listaEstadoOrdencompra.push({label: 'Anulados', value: '0'});
        listaEstadoOrdencompra.push({label: 'Nuevos', value: '1'});
        listaEstadoOrdencompra.push({label: 'Confirmados', value: '2'});
        listaEstadoOrdencompra.push({label: 'En camino', value: '3'});
        listaEstadoOrdencompra.push({label: 'Entregado', value: '4'});
        return listaEstadoOrdencompra;
    }

    public static obtenerListaEstadosInactivosActivos(conTodos?) {
        const listaEstadoOrdencompra = [];
        if (conTodos == this.PARAM.SI) {
            listaEstadoOrdencompra.push({label: 'Todas', value: this.PARAM.TODOS});
        }
        listaEstadoOrdencompra.push({label: 'Anuladas', value: '0'});
        listaEstadoOrdencompra.push({label: 'Activas', value: '1'});
        return listaEstadoOrdencompra;
    }

    public static obtenerListaMeses() {
        return [{
            label: 'Enero',
            value: 1
        }, {
            label: 'Febrero',
            value: 2
        }, {
            label: 'Marzo',
            value: 3
        }, {
            label: 'Abril',
            value: 4
        }, {
            label: 'Mayo',
            value: 5
        }, {
            label: 'Junio',
            value: 6
        }, {
            label: 'Julio',
            value: 7
        }, {
            label: 'Agosto',
            value: 8
        }, {
            label: 'Septiembre',
            value: 9
        }, {
            label: 'Octubre',
            value: 10
        }, {
            label: 'Noviembre',
            value: 11
        }, {
            label: 'Diciembre',
            value: 12
        }];
    }

    public static obtenerListaAnios() {
        const currentAnio = (new Date()).getFullYear();
        const anioList = [];
        for (let i = 2016; i < currentAnio; i++) {
            anioList.push({value: i, label: i});
        }
        anioList.push({value: currentAnio, label: currentAnio});
        for (let i = currentAnio + 1; i <= 2040; i++) {
            anioList.push({value: i, label: i});
        }

        return anioList;
    }

    public static obtenerListaUbicacionesPagina() {
        const listaUbicacionesPagina = [];
        listaUbicacionesPagina.push({label: 'Header', value: '1'});
        listaUbicacionesPagina.push({label: 'Footer', value: '2'});
        return listaUbicacionesPagina;
    }


    public formatearFechaParaVista(fecha): any {
        let fechaSeteada = null;
        if (fecha && fecha !== '-') {
            fechaSeteada = new Date(fecha + " " + "00:00:01");
        }
        return fechaSeteada;
    }

    /**
     * Validadores
     */

    public getPageOptions(): any[] {
        return [5, 10, 20, 30, 50, 100];
    }

    public obtenerFechaSegunFormato(fecha, formato): any {
        let fechaSeteada = "";
        if (fecha && fecha !== '-') {
            fechaSeteada = moment(fecha).format(formato);
        }
        return fechaSeteada;
    }

    public obtenerInicioDeMes() {
        const fechaActual = new Date();
        return new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1);
    }

    public obtenerInicioDeSemana() {
        const fechaSemana = moment().startOf('week').toDate();
        return moment(fechaSemana).add(1, "days").toDate();
    }


    public validarInputSearch(busqueda) {
        if (busqueda != undefined && busqueda != null && busqueda != "") {
            if (!Number.isNaN(busqueda)) {
                return busqueda;
            } else {

                const texto = busqueda.replace(/[^\w\s]/gi, '');
                if (texto == '') {
                    return UtilityService.PARAM.TODOS;
                }
                return texto;
            }

        }
        return UtilityService.PARAM.TODOS;
    }

    public getColorLine(valor){
        if (valor % 3 == 0){
            return "success";
        } else if (valor % 2 == 0){
            return "danger";
        } else {
          return "primary";
        }
    }

    public getGrupoDocumentos(){
        return [
            {
                id: '1',
                descripcion: 'Documentos Ingresos Vitae'
            },
            {
                id: '2',
                descripcion: 'Registros de Ingreso'
            },
            {
                id: '3',
                descripcion: 'Cargo de Entrega'
            },
            {
                id: '4',
                descripcion: 'Documentos Cese'
            }
        ];
    }

    public getDocumentos(){
        const arraydocumentos = [
            {
                id: '1',
                descripcion: 'Curriculum Vitae',
                grupo: '1'
            },
            {
                id: '2',
                descripcion: 'Certificados de Estudios y Trabajos',
                grupo: '1'
            },
            {
                id: '3',
                descripcion: 'Foto para Fotocheck',
                grupo: '1'
            },
            {
                id: '4',
                descripcion: 'Copia DNI',
                grupo: '1'
            },
            {
                id: '5',
                descripcion: 'Copia DNI derechohabientes',
                grupo: '1'
            },
            {
                id: '6',
                descripcion: 'Partido de matrimonio / Escritura de union de hecho',
                grupo: '1'
            },
            {
                id: '7',
                descripcion: 'Antecedentes Policiales',
                grupo: '1'
            },
            {
                id: '8',
                descripcion: 'Antecedentes Penales',
                grupo: '1'
            },
            {
                id: '9',
                descripcion: 'Certificado 5ta Categoría',
                grupo: '1'
            },
            {
                id: '10',
                descripcion: 'Elección Sistema De Pensiones',
                grupo: '1'
            },
            {
                id: '11',
                descripcion: 'Copia de Recibo de Luz / Agua',
                grupo: '1'
            },
            {
                id: '12',
                descripcion: 'Fotos Fachada Domicilio',
                grupo: '1'
            },
            {
                id: '13',
                descripcion: 'DJ Antecedentes Personales, Laborales y Patrimoniales',
                grupo: '2'
            },
            {
                id: '14',
                descripcion: 'Actualización de Identificación del Empleado',
                grupo: '2'
            },
            {
                id: '15',
                descripcion: 'DJ de Domicilio',
                grupo: '2'
            },
            {
                id: '16',
                descripcion: 'Autorización de Visita Domiciliaria',
                grupo: '2'
            },
            {
                id: '17',
                descripcion: 'DJ de Elección de Sistema Pensionario',
                grupo: '2'
            },
            {
                id: '18',
                descripcion: 'Solicitud de Asignación Familiar',
                grupo: '2'
            },
            {
                id: '19',
                descripcion: 'Ficha de Sintomatología',
                grupo: '2'
            },
            {
                id: '20',
                descripcion: 'Elección de Banco Sueldo y CTS',
                grupo: '2'
            },
            {
                id: '21',
                descripcion: 'DACHA-UIF-M-01 Prevención LA-FT v.2',
                grupo: '3'
            },
            {
                id: '22',
                descripcion: 'DACHA-UIF-C-01 Codigo de Conducta',
                grupo: '3'
            },
            {
                id: '23',
                descripcion: 'RIT Chavimochic',
                grupo: '3'
            },
            {
                id: '24',
                descripcion: 'RISST Chavimochic',
                grupo: '3'
            },
            {
                id: '25',
                descripcion: 'Contrato de trabajo',
                grupo: '3'
            },
            {
                id: '26',
                descripcion: 'Compromiso de confindencialidad',
                grupo: '3'
            },
            {
                id: '27',
                descripcion: 'Carta oferta',
                grupo: '3'
            },
            {
                id: '28',
                descripcion: 'Recomendaciones de SST',
                grupo: '3'
            },
            {
                id: '29',
                descripcion: 'Registro de inducción',
                grupo: '3'
            },
            {
                id: '30',
                descripcion: 'Anexo 09 / lavado de activos',
                grupo: '3'
            },
            {
                id: '31',
                descripcion: 'Memorandum de Iturri APP',
                grupo: '3'
            },
            {
                id: '32',
                descripcion: 'EMO',
                grupo: '3'
            },
            {
                id: '33',
                descripcion: 'Entrevista de salida',
                grupo: '4'
            },
            {
                id: '34',
                descripcion: 'Carta de renuncia',
                grupo: '4'
            },
            {
                id: '35',
                descripcion: 'Notificación de Cese',
                grupo: '4'
            },
            {
                id: '36',
                descripcion: 'Correo de baja',
                grupo: '4'
            }
        ];

        return arraydocumentos;
    }

    public getDocumentoText(documentotipo) {
        const documentoList = this.getDocumentos();
        for (const documento of documentoList) {
            if (documentotipo == documento.id){
                return documento.descripcion;
            }
        }

        return null;
    }

    /**
     * Parsers data
     */
    public parseData(data, tablaDescripcion?: string, tablaId?: string): any {
        let dataObj = {tipo: this.HTTP_RESPONSE.MALFORMED_JSON, mensajes: ['JSON mal formado', data], data: null};
        if (data !== null && typeof data === 'object') {
            if (data.data !== null && typeof data.data === 'object') {
                dataObj = data;
                if (!data.tipo) {
                    dataObj.tipo = this.HTTP_RESPONSE.SUCCESS;
                }
            } else {
                /**
                 * Si la respuesta es pura sin tipo ni mensajes seteo data a la respuesta
                 */
                if (data.data && data.tipo && data.mensajes) {
                    dataObj = data;
                } else {
                    if (!data.tipo && !data.mensajes) {
                        dataObj = {tipo: this.HTTP_RESPONSE.SUCCESS, mensajes: [], data};
                        if (data.mensajes && Array.isArray(data.mensajes)) {
                            dataObj = {tipo: this.HTTP_RESPONSE.SUCCESS, mensajes: data.mensajes, data};
                        }
                        if (data.mensajes && !Array.isArray(data.mensajes)) {
                            dataObj = {tipo: this.HTTP_RESPONSE.SUCCESS, mensajes: [data.mensajes], data};
                        }
                    } else {
                        if (data.tipo === this.HTTP_RESPONSE.PERMISION_ERROR) {
                            dataObj = data;
                        }
                        if (data.tipo === this.HTTP_RESPONSE.SUCCESS) {
                            if (data.mensajes && Array.isArray(data.mensajes)) {
                                dataObj = {tipo: data.tipo, mensajes: data.mensajes, data};
                            } else {
                                dataObj = {tipo: data.tipo, mensajes: [data.mensajes], data};
                            }
                        }
                        if (data.tipo === this.HTTP_RESPONSE.WARNING) {
                            if (data.mensajes && Array.isArray(data.mensajes)) {
                                dataObj = {tipo: data.tipo, mensajes: data.mensajes, data};
                                dataObj = {tipo: data.tipo, mensajes: data.mensajes, data};
                            } else {
                                dataObj = {tipo: data.tipo, mensajes: [data.mensajes], data};
                            }
                        }
                        if (data.tipo === this.HTTP_RESPONSE.INFO) {
                            if (data.mensajes && Array.isArray(data.mensajes)) {
                                dataObj = {tipo: data.tipo, mensajes: data.mensajes, data};
                            } else {
                                dataObj = {tipo: data.tipo, mensajes: [data.mensajes], data};
                            }
                        }
                        if (data.tipo === this.HTTP_RESPONSE.ERROR) {
                            if (data.mensajes && Array.isArray(data.mensajes)) {
                                /**
                                 * Si la respuesta tiene alguno de los siguientes problemas parseo
                                 * la respuesta indicando el origen del problema
                                 */
                                    // tslint:disable-next-line:max-line-length
                                const mensajeServidorError = this.parseResponseErrorDatabase(data.mensajes, tablaDescripcion, tablaId);
                                dataObj = {tipo: data.tipo, mensajes: mensajeServidorError, data};
                            } else {
                                dataObj = {tipo: data.tipo, mensajes: [data.mensajes], data};
                            }
                        }
                    }
                }
            }
        }
        return dataObj;
    }

    public parseResponseErrorDatabase(mensajes: any[], tablaDescripcion: string, tablaId: string) {
        let mensajeArrayProcesada = [];

        mensajes.forEach(error => {
            /**
             * Pregunto si el error es por algun fk delete
             */
            if (error.includes('Cannot delete or update a parent row: a foreign key constraint fails')) {
                // tslint:disable-next-line:max-line-length
                mensajeArrayProcesada.push('El elemento con ID "' + tablaId + '" de la tabla "' + tablaDescripcion + '" está referenciado en otra(s) tabla(s), ' +
                    'por favor contacta al admininistrador para notificar el problema e ' +
                    'indicale el id y la tabla que tienen problemas.');
            }
        });

        if (!mensajeArrayProcesada.length) {
            mensajeArrayProcesada = mensajes;
        }

        return mensajeArrayProcesada;
    }

    /**
     * Formatos de fecha
     */
    public obtenerFechaActual(): any {
        return moment(new Date()).format('L');
    }

    public obtenerFechaHoraActual(): any {
        return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }
    public obtenerHoraActual(): any {
        return moment(new Date()).format('HH:mm');
    }

    public formatearFechaYHora(fecha, hora) {
        return moment(fecha).format('YYYY-MM-DD') + ' ' + hora;
    }

    public obtenerListaBloquePanelimagen(): any {
        return [
            {
                id: '0',
                value: 'Bloque ofertas (Superior)'
            },
            {
                id: '1',
                value: 'Bloque ofertas (Inferior)'
            },
            {
                id: '2',
                value: 'Bloque productos por identificador'
            }
        ];
    }

    public obtenerListaColumnasBloquePanelimagen(): any {
        return [
            {
                id: '1',
                value: 'Ocupa 1 columna'
            },
            {
                id: '2',
                value: 'Ocupa 2 columnas'
            },
            {
                id: '3',
                value: 'Ocupa 3 columnas'
            },
            {
                id: '4',
                value: 'Ocupa 4 columna'
            },
            {
                id: '5',
                value: 'Ocupa 5 columnas'
            },
            {
                id: '6',
                value: 'Ocupa 6 columnas'
            },
            {
                id: '7',
                value: 'Ocupa 7 columna'
            },
            {
                id: '8',
                value: 'Ocupa 8 columnas'
            },
            {
                id: '9',
                value: 'Ocupa 9 columnas'
            },
            {
                id: '10',
                value: 'Ocupa 10 columna'
            },
            {
                id: '11',
                value: 'Ocupa 11 columnas'
            },
            {
                id: '12',
                value: 'Ocupa 12 columnas'
            }
        ];
    }

    public obtenerPaletaColores(): any {
        return [
            {id: "1", color: "#000000"},
            {id: "2", color: "#2E64FE"},
            {id: "3", color: "#DF0101"},
            {id: "4", color: "#088A4B"},
            {id: "5", color: "#FF0080"},
            {id: "6", color: "#FA5858"},
            {id: "7", color: "#086A87"},
            {id: "8", color: "#FA5882"},
            {id: "9", color: "#DBA901"},
            {id: "10", color: "#2E9AFE"},
            {id: "11", color: "#EE7115"},
            {id: "12", color: "#20D860"},
            {id: "13", color: "#D779D4"},
            {id: "14", color: "#C77557"},
            {id: "15", color: "#F6E10C"},
        ];
    }

    public ubicarPuntoCentralDentroDeAreaEspecifica(array_points) {

        const puntoCentral = {
            latitud: null,
            longitud: null,
        };

        let latitudMin = array_points[0].latitude * 1;
        let longitudMin = array_points[0].longitude * 1;

        let latitudMax = array_points[0].latitude * 1;
        let longitudMax = array_points[0].longitude * 1;

        for (let i = 0; i < array_points.length; i++) {

            const point = array_points[i];

            if (latitudMin < point.latitude * 1) {
                latitudMin = point.latitude * 1;
            }
            if (latitudMax > point.latitude * 1) {
                latitudMax = point.latitude * 1;
            }

            if (longitudMin < point.longitude * 1) {
                longitudMin = point.longitude * 1;
            }
            if (longitudMax > point.longitude * 1) {
                longitudMax = point.longitude * 1;
            }
        }

        puntoCentral.latitud = (latitudMin + latitudMax) / 2;
        puntoCentral.longitud = (longitudMin + longitudMax) / 2;

        return puntoCentral;
    }

    public cantidadMaximaDigitosInputs() {
        return 9;
    }

    public formatoNumero(amount, decimales?) {
        const respaldo = amount;
        const decimals = Number(decimales) >= 0 ? decimales : 2;
        amount += '';
        if (amount === 'NaN' || amount === 'undefined' || amount === 'Infinity') {
            amount = '0';
        }
        if (isNaN(amount)) {
            return respaldo;
        }

        amount = parseFloat(amount); // convierte a numero
        amount = UtilityService.round(amount, decimals); // devuelve un string
        const codIdioma = this.obtenerIdiomaLocal();
        amount = (amount).toLocaleString(codIdioma, {minimumFractionDigits: decimals}); // retorna string
        return amount;
    }

    public obtenerIdiomaLocal() {
        // const localStorage = SecurityService.obtenerLocalActual();
        // if (localStorage) {
        //    return localStorage.local_idioma;
        // }
        return 'en-IN';
    }

    public obtenerFormatoVistaAMPM(fecha) {
        return UtilityService.formatearFecha(fecha, 'DD/MM/yyyy') + ' ' + UtilityService.formatearFecha(fecha, 'hh:mm A');
    }

    public obtenerFormatoVistaDiaMes(fecha) {
        return UtilityService.formatearFecha(fecha, 'DD/MM');
    }

    public obtenerFormatoVistaDDMMYYY(fecha) {
        return UtilityService.formatearFecha(fecha, 'DD/MM/yyyy');
    }

    public obtenerFormatoBD(fecha) {
        return UtilityService.formatearFecha(fecha, 'yyyy-MM-DD');
    }

    public valorEstaVacio(valor){
        return (valor == null || valor == "");
    }

    public obtenerFormatoVistaHora(fecha) {
        return UtilityService.formatearFecha(fecha, 'hh:mm A');
    }
    public obtenerFormatoHoraBD(fecha) {
        return UtilityService.formatearFecha(fecha, 'HH:mm');
    }

    public obtenerAnioActual() {
        return new Date().getFullYear();
    }

    public obtenerPrecintoAduanaFinal(ordenServicio) {
        let precintoFinal = ordenServicio.ordenservicio_precintoaduana;

        if (ordenServicio.ordenservicio_precintoadicional2) {

            precintoFinal = ordenServicio.ordenservicio_precintoadicional2nombre;

        } else if (ordenServicio.ordenservicio_precintoadicional1) {

            precintoFinal = ordenServicio.ordenservicio_precintoadicional1nombre;

        } else if (ordenServicio.ordenservicio_precintofinal) {

            precintoFinal = ordenServicio.ordenservicio_precintofinal;

        }

        return precintoFinal;
    }

    public obtenerNombreTipoEmbarque(tipoembarque, nombreLargo = false) {
        let txt = "";
        switch (tipoembarque) {
            case '1':
                txt = nombreLargo ? "LOCAL DE EXPORTADOR" : "LEX";
                break;
            case '2':
                txt = nombreLargo ? "DEPOSITO TEMPORAL / ALEATORIO" : "DT";
                break;
            case '3':
                txt = nombreLargo ? "DEPOSITO TEMPORAL / ALEATORIO" : "AF. SINI";
                break;
        }
        return txt;

    }

    public obtenerNombreCanal(canal) {
        let txt = "";
        switch (canal) {
            case '1':
                txt = "ROJO";
                break;
            case '2':
                txt = "VERDE";
                break;
            default:
                txt = "SIN CANAL";
                break;
        }
        return txt;

    }

    public getPesoVGM(taraContenedor: number, pesoBruto: number) {
        return Number(taraContenedor) + Number(pesoBruto);
    }

    public getTaraCamion(pesocamion, pesoBruto) {
        if (pesocamion * 1 > pesoBruto * 1) {
            return pesocamion - pesoBruto;
        }
        else {
            return 0;
        }
    }

    public getObjForm(values): any{

        const arrayvalues = Object.entries(values);
        const objFormControl = {};

        for (const [entry, value]  of arrayvalues) {
            objFormControl[entry] = [value];
        }

        return objFormControl;
    }

    verArchivo(rutaarchivo){
        window.open(this.CDN_BASE + rutaarchivo, '_blank');
    }
}

