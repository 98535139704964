export const environment = {
  production: true,
  API_URI: 'https://rrhh.api.qa.iturriapp.com/api/rest/',
  PUBLIC_URI: 'https://rrhh.api.qa.iturriapp.com/public/rest/',
  CDN_BASE: 'https://rrhh.api.qa.iturriapp.com/',
  CDN_CLOUDINARY: '',
  PARAM: {
    ACTIVO: '1',
    INACTIVO: '0',
    SI: '1',
    NO: '0',
    TODOS: '-1',
    VACIO: ''
  },
  HTTP_RESPONSE: {
    SUCCESS: 1,
    WARNING: 2,
    ERROR: 3,
    INFO: 4,
    PERMISION_ERROR: 401,
    CODE_NOT_DEFINED: 601,
    MALFORMED_JSON: 701,
    ACCESS_DENIED: 403
  },
};
