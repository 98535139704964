import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatCheckboxChange} from "@angular/material/checkbox";

@Component({
    selector: 'app-select-check-all',
    templateUrl: "./select-check-all.component.html",
    styleUrls: ['./select-check-all.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SelectCheckAllComponent {
    @Input() model: FormControl;
    @Input() values = [];
    @Input() keyid = null;
    @Input() text = 'SELECCIONAR TODOS';

    isChecked(): boolean {
        return this.model.value && this.values.length
            && this.model.value.length === this.values.length;
    }

    isIndeterminate(): boolean {
        return this.model.value && this.values.length && this.model.value.length
            && this.model.value.length < this.values.length;
    }

    toggleSelection(change: MatCheckboxChange): void {
        let valuesAlt = this.values;

        if (this.keyid != null){
            valuesAlt = [];
            for (const data of this.values) {
                if (this.keyid in data) {
                    valuesAlt.push(data[this.keyid]);
                }
            }
        }

        if (change.checked) {
            this.model.setValue(valuesAlt );
        } else {
            this.model.setValue([]);
        }
    }
}
