import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {CommonResponse} from "../response/common.response";
import {UtilityService} from "../services/utility.service";
import {LocalUsuarioRepository} from "../../data/datasource/local/usuario/local.usuario.repository";
import {UsuarioEntity} from "../entity/usuario.entity";
import {ApiUsuarioRepository} from "../../data/datasource/api/usuario/api.usuario.repository";

@Injectable({
    providedIn: 'root'
})
export class Usuario extends UsuarioEntity {
    PARAM = environment.PARAM;

    constructor(
        public apiUsuarioRepository?: ApiUsuarioRepository,
        public localUsuarioRepository?: LocalUsuarioRepository
    ) {
        super();
    }

    /**
     * Armar el objeto en base a sus atributos: valor
     * Valido si el service 'UsuarioService' esta inyectado y es un objeto,
     * para pasarlo como parametro en el constructor
     */
    public static build(data: {}, apiUsuarioRepository?: ApiUsuarioRepository) {
        const usuario = new Usuario();
        usuario.apiUsuarioRepository = apiUsuarioRepository;
        // tslint:disable-next-line:forin
        for (const propiedad in data) {
            usuario[propiedad] = data[propiedad];
        }
        return usuario;
    }

    public async login(credenciales: any): Promise<CommonResponse> {
        const response = await this.apiUsuarioRepository.login(credenciales);
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                // @ts-ignore
                    .map(Usuario.build)
                    .filter(Boolean);
            } else {
                response.data = Usuario.build(response.data);
            }
        }
        return response;
    }

    public guardarSesion(sesionData) {
        if (sesionData.token) {
            this.localUsuarioRepository.guardarUsuarioSesion(sesionData);
            this.localUsuarioRepository.guardarToken(sesionData.token);
        }
    }

    public async deleteTokenByToken(): Promise<CommonResponse> {
        const response = await this.apiUsuarioRepository.deleteTokenByToken(this.localUsuarioRepository.obtenerToken());
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                // @ts-ignore
                    .map(Usuario.build)
                    .filter(Boolean);
            } else {
                response.data = Usuario.build(response.data);
            }
        }
        return response;
    }

    public async obtenerListaPorPaginacion(busqueda: any, pagina: any, registros: any): Promise<CommonResponse> {
        const response = await this.apiUsuarioRepository.obtenerListaPorPaginacion(busqueda, pagina, registros);
        if (response.data) {
            if (Array.isArray(response.data)) {
                response.data = response.data
                    // @ts-ignore
                    .map(Usuario.build)
                    .filter(Boolean);
            } else {
                response.data = Usuario.build(response.data);
            }
        }
        return response;
    }

    public async registrar(objeto: any): Promise<CommonResponse> {
        return await this.apiUsuarioRepository.registrar(objeto);
    }

    public async editar(objeto: any): Promise<CommonResponse> {
        return await this.apiUsuarioRepository.editar(objeto);
    }

    public async inactivar(id: any): Promise<CommonResponse> {
        return await this.apiUsuarioRepository.inactivar(id);
    }

    public async activar(id: any): Promise<CommonResponse> {
        return await this.apiUsuarioRepository.activar(id);
    }

    public async getCargosUsuario(): Promise<CommonResponse> {
        return await this.apiUsuarioRepository.getCargosUsuario();
    }


    public cerrarSesion() {
        this.localUsuarioRepository.eliminarUsuarioSesion();
        this.localUsuarioRepository.eliminarToken();
    }

    /**
     * Valida si el producto tiene un id valido
     */
    public tieneIdValido() {
        return UtilityService.esUnIDValido(this.usuario_id);
    }

    /**
     * Valida si el objeto esta activo
     */
    public estaActivo() {
        // tslint:disable-next-line:triple-equals
        return this.usuario_estado == this.PARAM.ACTIVO;
    }

    /**
     * Valida si el objeto esta activo
     */
    public estadoDescripcion() {
        // tslint:disable-next-line:triple-equals
        return this.estaActivo() ? 'Activo' : 'Inactivo';
    }

    /**
     * Resetar el objeto con valores por defecto
     */
    public reset() {
        this.setUsuario_estado(this.PARAM.ACTIVO);
    }

}
