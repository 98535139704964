import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import {Usuario} from "../models/usuario.model";
import {Router} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private usuario: Usuario, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http
        .post<any>(`${environment.API_URI}/authenticate`, {
          username,
          password,
        })
        .pipe(
            map((user) => {
              // store user details and jwt token in local storage to keep user logged in between page refreshes

              localStorage.setItem("currentUser", JSON.stringify(user));
              this.currentUserSubject.next(user);
              return user;
            })
        );
  }

  logout() {
      this.usuario.deleteTokenByToken().then(async response => {
          // @ts-ignore
          // remove user from local storage to log user out
          localStorage.clear();
          this.currentUserSubject.next(null);
          this.router.navigate(["/authentication/signin"]);
      });
  }
}
