import {LOCALE_ID, NgModule} from "@angular/core";

import { SharedModule } from "./shared/shared.module";

import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./shared/components/main-view/header/header.component";
import { PageLoaderComponent } from "./shared/components/main-view/page-loader/page-loader.component";
import { SidebarComponent } from "./shared/components/main-view/sidebar/sidebar.component";
import { AuthLayoutComponent } from "./shared/components/main-view/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./shared/components/main-view/app-layout/main-layout/main-layout.component";
import { fakeBackendProvider } from "./shared/interceptor/fake-backend";
import { ErrorInterceptor } from "./shared/interceptor/error.interceptor";
import { JwtInterceptor } from "./shared/interceptor/jwt.interceptor";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";
import {TranslateModule, TranslateLoader, TranslateService} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ClickOutsideModule } from "ng-click-outside";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import {HttpCancelService} from "./shared/interceptor/http.cancel.service";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {CustomMatPaginatorIntl} from "./shared/services/custom-mat-paginator-intl";
import {ToastrModule} from "ngx-toastr";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomModule} from "./shared/custom/custom-material.module";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    CustomModule,
    ClickOutsideModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    // core & shared
    SharedModule,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    HttpCancelService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl}

    /*{
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new CustomMatPaginatorIntl(translateService).getPaginatorIntl()
    },*/
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
