import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../../../environments/environment";
import {CommonResponse} from "../../../../shared/response/common.response";
import {SecurityService} from "../../../../shared/services/security.service";
import {UtilityService} from "../../../../shared/services/utility.service";
import {Usuario} from "../../../../shared/models/usuario.model";

@Injectable({
    providedIn: 'root'
})
export class ApiUsuarioRepository {
    /**
     * Contiene la ruta de conexión a servicios publicos
     */
    API_URI = environment.API_URI;
    PUBLIC_URI = environment.PUBLIC_URI;

    // tslint:disable-next-line:max-line-length
    constructor(private http: HttpClient, private securityService: SecurityService, private utilityService: UtilityService) {
    }

    public async login(credentials: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.PUBLIC_URI + 'usuario/login', credentials).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async deleteTokenByToken(token: string): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.PUBLIC_URI + 'usuario/deleteTokenByToken/' + token).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async obtenerListaPorPaginacion(busqueda: any, pagina: any, registros: any): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'usuario/listarPorPaginacion/' + busqueda + '/' + pagina + '/' + registros).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async registrar(objeto: Usuario): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.post(this.API_URI + 'usuario/usuario', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async editar(objeto: Usuario): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.put(this.API_URI + 'usuario/usuario', objeto).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async inactivar(id): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'usuario/inactivar/' + id).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async activar(id): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'usuario/activar/' + id).toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }

    public async getCargosUsuario(): Promise<CommonResponse> {
        try {
            const responseSuccess = await this.http.get(this.API_URI + 'usuario/getCargosUsuario').toPromise();
            return this.utilityService.parseData(responseSuccess);
        } catch (responseError) {
            await this.utilityService.parseData(responseError);
        }
    }


}
