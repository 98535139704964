import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {takeUntil, tap} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {HttpCancelService} from "./http.cancel.service";
import {Trabajador} from "../models/trabajador.model";
import {Router} from "@angular/router";
import {LocalUsuarioRepository} from "../../data/datasource/local/usuario/local.usuario.repository";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  HTTP_RESPONSE = environment.HTTP_RESPONSE;

  constructor(
      private localUsuarioRepository: LocalUsuarioRepository,
      private httpCancelService: HttpCancelService,
      private trabajador: Trabajador,
      private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headersConfig = {};

    const token = this.localUsuarioRepository.obtenerToken();

    if (token) {
      if (req.url.toString() && req.url.toString().indexOf('api/rest') >= 0) {
        headersConfig['Authorization'] = 'Token token="' + token + '"';
      }
    }

    const request = req.clone({setHeaders: headersConfig});
    return next.handle(request).pipe(tap(async (event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        event = event.clone({body: await this.redireccionarSegunRespuesta(event.body)});
      }
      return event;
      // Cancela la peticion al navegar entre routers
    }), takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }

  private async redireccionarSegunRespuesta(body: any) {
    if (Number(body.tipo) === Number(this.HTTP_RESPONSE.PERMISION_ERROR)) {
      this.trabajador.cerrarSesion();
      await this.router.navigateByUrl('/authentication/signin');
    }
  }
}
