<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header"  style="background-color: #1a202e !important;">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink={{homePage}}>
        <img style="width: 121px;height: 38px;" src="assets/images/logoiturriapprrhh.png" alt="" />
        <!--<span class="logo-name" style="color: #e6e6e6 !important;">ITURRIAPP</span>-->
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">

        <li class="fullscreen m-r-10">
          <button mat-icon-button (click)="logout()" class=" nav-notification-icons">
            <mat-icon>power_settings_new</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
